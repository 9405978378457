import React, { useEffect, useState } from "react";
import WelcomeBackCard from "../../components/website/overview/WelcomeBackCard";
import IconDataBox from "../../components/website/overview/IconDataBox";
import { ReactComponent as ViewsIcon } from "../../assets/Icons/Views.svg";
import { ReactComponent as PlusBorderIcon } from "../../assets/Icons/PlusBorder.svg";
import { ReactComponent as ArticleIcon } from "../../assets/Icons/WebsiteIcons/Blog.svg";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { websiteState } from "../../state/websitesState";
import KeywordsTable from "../../components/website/overview/KeywordsTable";
import LastGeneratedArticles from "../../components/website/overview/LastGeneratedArticles";
import { websiteDashboardDataState } from "../../state/websiteDashboardDataState";
import WebsiteTabsNav from "../../components/website/WebsiteTabsNav";
import ConnectCmsModal from "../../components/modals/ConnectCmsModal";
import { useSearchParams } from "react-router-dom";
import { publishIntegrationState } from "../../state/publishIntegrationState";
import CardTitle from "../../components/common/CardTitle";
import Card from "../../components/common/Card";
import styled from "styled-components";
import Button from "../../components/common/form/Button";
import { featuresState } from "../../state/featuresState";

const CardStyle = styled(Card)`
  cursor: pointer;

  background: white;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--primary-purple);
  font-weight: bold;
  box-shadow: -10px 10px 20px -5px rgba(0, 0, 0, 0.1);

  svg {
    fill: var(--primary-purple);
    width: 25px;
    height: 25px;
  }
`;

const SingleWebsite: React.FC = () => {
  const { websiteId } = useParams();
  const allowedFeatures = useRecoilValue(featuresState);
  const website = useRecoilValue(websiteState(websiteId as string));
  const websiteDashboardData = useRecoilValue(
    websiteDashboardDataState(websiteId as string)
  );
  const publishIntegration = useRecoilValue(
    publishIntegrationState(websiteId as string)
  );
  const [showConnectCmsModal, setShowConnectCmsModal] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const connectCms = searchParams.get("connectCms");
    if (connectCms) {
      setShowConnectCmsModal(true);
    }
  }, [searchParams]);

  console.log(allowedFeatures);

  return (
    <>
      {website && (
        <>
          {/* <WebsiteTabsNav /> */}
          {/* <WebsitePageLayout /> */}
          <div className="grid">
            <div className="col-8">
              <WelcomeBackCard
                isCmsConnected={!!publishIntegration}
              ></WelcomeBackCard>
            </div>

            <div className="col-4">
              <IconDataBox
                iconColor={"purple"}
                icon={<ArticleIcon />}
                boxTitle={"Posted Articles"}
                boxNumber={websiteDashboardData.publishedArticlesCount}
                marginBottom={true}
              />
              <IconDataBox
                iconColor={"green"}
                icon={<ViewsIcon />}
                boxTitle={"Total views"}
                boxNumber={websiteDashboardData.totalViews}
              />
            </div>

            <div className="col-12">
              <KeywordsTable />
            </div>

            {/* <div className="col-4">
              <CardTitle title="Quick actions" />
              <div className="grid">
                <div className="col-12">
                  <CardStyle>
                    <PlusBorderIcon />
                    <span>Create blog post</span>
                  </CardStyle>
                </div>
                <div className="col-12">
                  <CardStyle>
                    <PlusBorderIcon />
                    <span>Quick Schedule</span>
                  </CardStyle>
                </div>
              </div>
            </div> */}

            <div className="col-12">
              <LastGeneratedArticles />

              {/* <WebsiteMetaData website={website}></WebsiteMetaData> */}
            </div>
          </div>
        </>
      )}
      {showConnectCmsModal && (
        <ConnectCmsModal onClose={() => setShowConnectCmsModal(false)} />
      )}
    </>
  );
};

export default SingleWebsite;

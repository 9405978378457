import styled from "styled-components";
import Link from "../../common/Link";
import { useRecoilValue } from "recoil";
import { userState } from "../../../state/userState";
import Badge from "../../common/Badge";
import { websiteState } from "../../../state/websitesState";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as LightbulbIcon } from "../../../assets/Icons/Lightbulb.svg";
import IconDataBox from "../../../components/website/overview/IconDataBox";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/Plus.svg";
import { ReactComponent as ViewsIcon } from "../../../assets/Icons/Views.svg";
import { ReactComponent as ArticleIcon } from "../../../assets/Icons/WebsiteIcons/Blog.svg";
import Button from "../../common/form/Button";
import { useState } from "react";
import GenerateArticleModal from "../../modals/generateArtical/GenerateArticleModal";
import React from "react";
import Preloader from "../../common/Preloader";
import useNavigator from "../../../hooks/useNavigator";

const NoticeWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: white !important;
    }
  }

  small {
    color: #d1d1d1;

    a {
      color: white;
    }
  }
`;

const CardStyle = styled.div`
  background: var(--title-color);
  padding: 0 3rem;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  color: white;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: Lato;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.045rem;
    text-transform: capitalize;
  }

  h2 {
    position: relative;
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 1rem */
    letter-spacing: -0.03rem;
    text-transform: capitalize;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  font-weight: bold;

  font-size: 12px;

  /* font-size: 14px; */
  background: var(--primary-purple);
  color: white;

  svg {
    fill: white;
    width: 15px;
    height: 15px;
  }
`;

interface Props {
  isCmsConnected: boolean;
}

const WelcomeBackCard: React.FC<Props> = ({ isCmsConnected }) => {
  const user = useRecoilValue(userState);
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const [showGenerateArticleModal, setShowGenerateArticleModal] =
    useState(false);

  const navigate = useNavigator();

  return (
    <CardStyle>
      <div className="grid">
        <div className="col-12">
          <h2>{new Date().toLocaleDateString()}</h2>
          <h1>Hello, {user?.firstName}</h1>
          <div className="flex mt-3">
            <span className="mr-2"> {website?.domain} - </span>
            {isCmsConnected ? (
              <Badge bgColor="purple">Connected</Badge>
            ) : (
              <Badge bgColor="purple">Not Connected</Badge>
            )}
            <br />
          </div>
          {!isCmsConnected && (
            <NoticeWrapper>
              <LightbulbIcon />
              <small>
                Navigate to the <Link path="integrations">Integrations</Link>{" "}
                tab to establish a connection with your CMS.
              </small>
            </NoticeWrapper>
          )}
        </div>
      </div>

      {showGenerateArticleModal && (
        <React.Suspense fallback={<Preloader />}>
          <GenerateArticleModal
            onHide={() => setShowGenerateArticleModal(false)}
            onSubmit={() => {
              setShowGenerateArticleModal(false);
              navigate(`/articles`);
            }}
          />
        </React.Suspense>
      )}
    </CardStyle>
  );
};

export default WelcomeBackCard;

import { InputText } from "primereact/inputtext";
import { SubmitHandler, useForm, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import RegexValidations from "../../../../core/validation/regexValidations";
import Preloader from "../../../common/Preloader";
import Button from "../../../common/form/Button";
import { useCallback, useState } from "react";
import crollerService from "../../../../core/services/croller.service";
import { InputTextarea } from "primereact/inputtextarea";
import Badge from "../../../common/Badge";
import CardTitle from "../../../common/CardTitle";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";
import Card from "../../../common/Card";
import { Language } from "../../../../core/types/language";

const InnerText = styled.div`
  position: absolute;
  top: 0%;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(10, 37, 64);
  font-size: 12px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;

  border-radius: 6px 0 0 6px;
  letter-spacing: -0.1px;
`;

const TextInsideInput = styled.div`
  position: relative;
  width: 100%;
  width: calc(100% - 171px);

  & input {
    margin-top: 0;
    padding-left: 65px;
  }
`;

const BadgeWrapper = styled.div`
  z-index: 22;
  position: absolute;
  left: 40px;
  top: -10px;
`;

const RequiredBadgeWrapper = styled.div`
  z-index: 22;
  position: absolute;
  right: 155px;

  top: 50%;
  transform: translate(-50%, -50%);
`;

const StackWrapper = styled.div`
  gap: 20px;
  margin-top: 10px;
  position: relative;
`;

const BusinessInfo = styled(Card)`
  .input-bg {
    background: var(--light-bg);
  }
`;

const ButtonStyle = styled(Button)`
  path {
    fill: "white" !important;
  }
`;

const SmallText = styled.small`
  color: var(--text-color);
`;
const EnterWebsiteAddress: React.FC = () => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext<CreateWebsiteRequestData>();

  const [isLoading, setIsLoading] = useState(false);

  const onScanWebsite = useCallback(async () => {
    setIsLoading(true);
    try {
      const isValid = await trigger("website.url");

      if (!isValid) throw new Error("Url is not valid");

      const scannedWebsiteMeta = await crollerService.scanWebsite(
        getValues("website.url")
      );

      setValue("website.name", scannedWebsiteMeta.name);
      setValue("website.description", scannedWebsiteMeta.description);
      setValue(
        "writer.language",
        scannedWebsiteMeta.language || Language.ENGLISH
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [getValues, setValue]);

  return (
    <div className="w-full">
      <div>
        <label htmlFor="username">Enter your business's website URL</label>

        <StackWrapper className="flex">
          <RequiredBadgeWrapper>
            <Badge bgColor="purple">Required</Badge>
          </RequiredBadgeWrapper>
          <TextInsideInput className={errors.website?.url ? "p-invalid" : ""}>
            <InnerText>https://</InnerText>
            <InputText
              disabled={isLoading}
              {...register("website.url", {
                required: true,
                setValueAs: (value: string) => {
                  const formatted = value
                    .replace("https://", "")
                    .replace("http://", "");
                  return `https://${formatted}`;
                },
                pattern: {
                  value: RegexValidations.url,
                  message: "Website address is invalid",
                },
              })}
              placeholder="yourwebsite.com"
              className={
                "w-full unmask-clarity" +
                (errors.website?.url ? "p-invalid" : "")
              }
            />
          </TextInsideInput>
          <ButtonStyle
            arrowPlacement="right"
            loading={isLoading}
            onClick={onScanWebsite}
          >
            Scan Website
          </ButtonStyle>
        </StackWrapper>
        <div className="mb-3 mt-3">
          <small
            style={{
              color: "red",
            }}
          >
            {errors.website?.url?.message}
          </small>
        </div>
      </div>
      <Divider />

      <div>
        <BusinessInfo className="relative">
          <BadgeWrapper>
            <Badge bgColor="yellow" textColor="title">
              Business Information
            </Badge>
          </BadgeWrapper>
          <CardTitle title="Business name:" className="mb-0"></CardTitle>

          <InputText
            disabled={isLoading}
            {...register("website.name", {
              required: {
                message: "business name is required",
                value: true,
              },
            })}
            placeholder="E.g Ghostwrite AI"
            className="border-none input-bg unmask-clarity"
          />
          <CardTitle
            title="Business description:"
            className="mb-0 mt-3"
          ></CardTitle>

          <InputTextarea
            autoResize
            className="border-none input-bg unmask-clarity"
            disabled={isLoading}
            {...register("website.description", {
              required: {
                message: "business description is required",
                value: true,
              },
            })}
            placeholder="E.g Ghostwrite AI is an AI tool that writes content to help increase website traffic and save time and money."
          />
        </BusinessInfo>
        <SmallText className="mt-3">
          <strong>We won't publish anything without your permission.</strong>
          <br></br>
          By clicking “Select Goal," you confirm ownership or permission to use
          this website's content, allowing Ghostwrite to scan and train on its
          data.
        </SmallText>
      </div>
    </div>
  );
};

export default EnterWebsiteAddress;

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import SelectKeywordsInput from "../../../keywords/SelectKeywordsInput";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { generateKeywordsByWebsiteState } from "../../../../state/generateKeywordsByWebsiteState";
import React from "react";
import Preloader from "../../../common/Preloader";

const keywordsHeader = "Keyword Pool with Ghostwrite's Recommendations:";

const middleText = `To add a new keyword, simply type your desired keyword into the input field above, and then press "Enter" to include it. Below, you'll find a list of the keywords that will be used later for genenrating the content. `;

const SuspendWrapper = () => {
  const { getValues, setValue } = useFormContext<CreateWebsiteRequestData>();
  const websiteDescription = getValues("website.description");
  const language = getValues("writer.language");

  const keywordsStateValue = useRecoilValue(
    generateKeywordsByWebsiteState({
      websiteDescription,
      language,
    })
  );

  useEffect(() => {
    keywordsStateValue && setValue("worker.keywords", keywordsStateValue);
  }, [keywordsStateValue]);

  return (
    <SelectKeywordsInput<CreateWebsiteRequestData>
      fieldName="worker.keywords"
      keywordsHeader={keywordsHeader}
      middleText={middleText}
    />
  );
};

const ChooseWriterKeywords: React.FC = () => {
  return (
    <React.Suspense
      fallback={
        <Preloader>
          Discover top-notch, tailor-made keywords for your website as we
          diligently scan its content.
        </Preloader>
      }
    >
      <SuspendWrapper />
    </React.Suspense>
  );
};

export default ChooseWriterKeywords;

import styled from "styled-components";
import LogoIcon from "../assets/Logo/LogoIcon.png";
import { useParams } from "react-router";
import { PaymentStatusType } from "../core/types/paymentStatusType";
import Link from "../components/common/Link";
import AppConfig from "../config/appConfig";
import Button from "../components/common/form/Button";

const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  align-self: center;
  align-content: center;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  max-width: 500px;
`;

const BoxImage2 = styled.img`
  width: 40px;
  box-sizing: content-box;
  object-fit: contain;
  margin-bottom: -6px;
  transition-duration: 0.25s;
`;

type MessageFactoryType = {
  [key in PaymentStatusType]: {
    message: string;
    title: string;
  };
};

const messagePerStatusFactory: MessageFactoryType = {
  [PaymentStatusType.SUCCESS]: {
    title: "Thank You!",
    message: "Your subscription has activated successfully",
  },
  [PaymentStatusType.PENDING]: {
    message:
      "We have received your order and wating for the payment to proccess. once it's activated you will receive an email. If you received an email, please",
    title: "Payment is Pending",
  },
  [PaymentStatusType.FAILED]: {
    message: "Payment Failed",
    title:
      "Your payment has been failed. please make sure you credit card is valid",
  },
};

const AfterPaymentPage: React.FC = () => {
  const { status } = useParams();
  const textObj = messagePerStatusFactory[status as PaymentStatusType];

  const refreshPage = () => {
    window.location.href = AppConfig.appUrl;
  };

  return (
    <BodyWrapper>
      <TextWrapper>
        <h1>
          <BoxImage2 src={LogoIcon} /> {textObj.title}
        </h1>
        <br></br>
        <h2>
          {textObj.message} <br></br>
          <br></br>
          <Button primary onClick={refreshPage}>
            Click Here to procceed
          </Button>
        </h2>
      </TextWrapper>
    </BodyWrapper>
  );
};

export default AfterPaymentPage;

import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import FormStepType from "../../common/form/types/formStepType";
import ChooseWriterGoal from "./steps/ChooseWriterGoal";
import ChooseWriterKeywords from "./steps/ChooseWriterKeywords";
import EnterWebsiteAddress from "./steps/EnterWebsiteAddress";
import SelectToneAndLanguage from "./steps/SelectToneAndLanguage";

export const createWebsiteSteps: FormStepType<CreateWebsiteRequestData>[] = [
  {
    component: <EnterWebsiteAddress />,
    fields: ["website.url", "website.description", "website.name"],
    nextButtonText: "Select Goal",
  },
  {
    title: "Choose Your Content Writer's Goal",
    component: <ChooseWriterGoal />,
    fields: ["writer.goal"],
    nextButtonText: "Select your writer's Tone & language",
  },
  {
    title: "Select Your Content Writer's Language & Tone",
    component: <SelectToneAndLanguage />,
    fields: ["writer.tone", "writer.language"],
    nextButtonText: "Let's add some trending keywords",
  },
  {
    title: "Enter Keywords to Shape Your Future Blog Posts",
    component: <ChooseWriterKeywords />,
    fields: ["worker.keywords"],
    nextButtonText: "Create business",
  },
  // {
  //   subTitle: "Set Your Writer's Blog Post Schedule",
  //   component: <SelectWriterSchedulingTime />,
  //   fields: "worker.cronExpression",
  //   nextButtonText: "Create website",
  // },
];

import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import FormStyle from "../../common/form/FormStyle";
import { createWebsiteSteps } from "./createWebsiteSteps";
import styled from "styled-components";
import Button from "../../common/form/Button";
import Link from "../../common/Link";
import { ReactComponent as CloseIcon } from "../../../assets/Icons/Close.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { websiteService } from "../../../core/services/website.service";
import { CronExpression } from "../../../common/types/cronExpression";
import { useRecoilState } from "recoil";
import { websitesStateSelector } from "../../../state/websitesState";
import { WriterTemplate } from "../../../core/types/writerTemplates";
import { GoalType } from "../../../core/types/goalType";
import { ToneType } from "../../../core/types/toneType";
import MinimalTopMenu from "./MinimalTopMenu";
import useMultiStepForm from "../../../hooks/useMultiStepForm";
import { Language } from "../../../core/types/language";

const Container = styled.div`
  height: calc(100vh - 75px);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: start;
`;

const CenteredForm = styled(FormStyle)`
  padding-top: 35px;
  display: flex;
  width: 700px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const InputsContainer = styled.div`
  margin: 70px 0;
  width: 100%;
`;

const BackButton = styled.div`
  position: absolute;
  top: 85px;
  left: 5px;
`;

const ExitButton = styled.div`
  position: absolute;
  top: 95px;
  right: 20px;

  & svg {
    width: 30px;
    height: 30px;
  }
`;

const SubTitle = styled.h2`
  /* text-align: center; */
  color: #425466;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.54px;
`;

const Title = styled.h1`
  text-align: center;

  /* position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 155px; */
  color: #0a2540;

  font-size: 48px;
  font-weight: 700;
  letter-spacing: -2.88px;
`;

const ProgWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #e6e6e6;
  height: 8px;
`;

const ProgBar = styled.div<{ $width?: number }>`
  height: 100%;
  width: ${(props) => (props.$width ? `${props.$width}%` : "0%")};
  background-image: linear-gradient(
    90deg,
    #a960ee,
    #f92b75,
    #90e0ff,
    #ffcb57,
    #f92b75,
    #90e0ff,
    #a960ee
  );
  background-clip: content-box;
  -webkit-background-clip: content-box; /* Safari/Chrome */
  animation: gradientAnimation 70s linear infinite;
  background-size: 300% auto;
  transition-duration: 0.4s;
`;

const AnimatedText = styled.span`
  font-weight: bold;
  background-clip: text;
  -webkit-background-clip: text; /* Safari/Chrome */
  color: transparent;
  background-image: linear-gradient(
    125deg,
    #a960ee,
    #f92b75,
    #90e0ff,
    #ffcb57,
    #f92b75,
    #90e0ff,
    #a960ee
  );
  animation: gradientAnimation 70s linear infinite;
  background-size: 300% auto;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 300% 50%;
    }
  }
`;

const CreateWebsiteForm: React.FC = () => {
  const navigate = useNavigate();
  const [websites, setWebsites] = useRecoilState(websitesStateSelector);
  const [searchParams] = useSearchParams();

  const onSubmit = async () => {
    try {
      const website = await websiteService.createWebsiteWithWorker(
        methods.getValues()
      );

      const connectCms = searchParams.get("connectCms");

      if (connectCms) {
        return navigate(`/websites/${website.id}?${searchParams.toString()}`);
      }
      setWebsites([...websites, website]);
      navigate(`/websites/${website.id}/`);
    } catch (err) {}
  };

  const {
    methods,
    component,
    showBackButton,
    onClickNextButton,
    progressPercentage,
    activeStep,
    isLoading,
    navigatePrevStep,
  } = useMultiStepForm<CreateWebsiteRequestData>(createWebsiteSteps, onSubmit, {
    worker: {
      keywords: [],
      cronExpression: CronExpression.THREE_TIMES_A_WEEK,
      isDisabled: true,
    },
    website: {
      description: "",
      category: "",
      url: searchParams.get("websiteUrl") || "",
    },
    writer: {
      templates: [...Object.values(WriterTemplate)],
      goal: GoalType.CONVERSION,
      tone: ToneType.CONVERSATIONAL,
      language: Language.ENGLISH,
    },
  });

  const shouldShowExitButton = useMemo(
    () => !searchParams.get("hideExit"),
    [searchParams]
  );

  return (
    <>
      <MinimalTopMenu title="Add your business" showAvater />
      <Container>
        {/* <ProgWrapper>
          <ProgBar $width={progressPercentage}></ProgBar>
        </ProgWrapper> */}
        {showBackButton && (
          <BackButton>
            <Button
              primary={false}
              arrowPlacement="left"
              onClick={navigatePrevStep}
            >
              Back
            </Button>
          </BackButton>
        )}

        {shouldShowExitButton && (
          <Link path="/" className="flex align-items-center">
            <ExitButton>
              <CloseIcon />
            </ExitButton>
          </Link>
        )}

        <FormProvider {...methods}>
          <CenteredForm
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          >
            <div>
              <Title>
                {createWebsiteSteps[activeStep].title ? (
                  createWebsiteSteps[activeStep].title
                ) : (
                  <>
                    Add Your <AnimatedText>Business</AnimatedText>
                  </>
                )}
              </Title>

              <InputsContainer>{component}</InputsContainer>
            </div>
            <Button
              primary
              arrowPlacement="right"
              disabled={!methods.formState.isValid}
              onClick={onClickNextButton}
            >
              {createWebsiteSteps[activeStep].nextButtonText}
            </Button>
          </CenteredForm>
        </FormProvider>
        {isLoading && <Preloader></Preloader>}
      </Container>
    </>
  );
};

export default CreateWebsiteForm;
